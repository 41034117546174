import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/globals.css'
import moment from 'moment-timezone'
import Head from 'next/head'

moment.locale('pt-br')

function App({ Component, pageProps, err }) {
  return <>
    <Head>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname
            });
          `,
        }}
      />
      {process.env.NEXT_PUBLIC_GOOGLE_ADS &&
        <>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ADS}`} />
          <script
            dangerouslySetInnerHTML={{
              __html: `
                gtag('js', new Date());
                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADS}');
              `,
            }}
          />
        </>
      }
    </Head>
    <Component {...pageProps} err={err} />
  </>
}

export default App
